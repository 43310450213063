export const enableResizingAllFalse = {
    top: false,
    bottom: false,
    right: false,
    left: false,
    topRight: false,
    bottomRight: false,
    bottomLeft: false,
    topLeft: false
};

export const initTable = () => [{cells: [{}]}];

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const generateHeaders = (num) => {
    let headers = [];

    while (num > 0) {
        let remainder = (num - 1) % 26;
        headers.unshift(alphabet[remainder]);
        num = Math.floor((num - 1) / 26);
    }

    return headers.join('');
};

export const parseHeaders = (str) => {
    const headers = str.toUpperCase();
    let num = 0;

    for (let i = 0; i < headers.length; i++) {
        const char = headers.charAt(i);
        const position = alphabet.indexOf(char) + 1;
        num = num * 26 + position;
    }

    return num;
};

export const adjustCellSizes = (data) => {
    const approxCharWidth = 11; // Приблизительная ширина одного символа в пикселях
    const lineHeight = 19; // Высота строки текста в пикселях
    const minWidth = 100; // Минимальная ширина ячейки в пикселях
    const minHeight = 30; // Минимальная высота ячейки в пикселях
    const maxWidth = 300; // Максимальная ширина ячейки в пикселях

    // Вспомогательная функция для оценки ширины текста
    function getTextWidth(text) {
        return (text || '').length * approxCharWidth;
    }

    // Вспомогательная функция для оценки высоты текста на основе ширины ячейки
    function estimateHeight(text, cellWidth) {
        const textWidth = getTextWidth(text);
        const linesNeeded = Math.ceil(textWidth / cellWidth); // Оценка количества строк
        return linesNeeded * lineHeight;
    }

    // Подготовка массивов для хранения максимальных размеров
    let maxWidths = new Array(data[0].cells.length).fill(minWidth);
    let maxHeights = new Array(data.length).fill(minHeight);

    // Первый проход: определение максимальной ширины каждой колонки с учетом максимальной ширины
    data.forEach((row) => {
        row.cells.forEach((cell, cellIndex) => {
            const cellWidth = getTextWidth(cell.value);
            maxWidths[cellIndex] = Math.min(Math.max(maxWidths[cellIndex], cellWidth, minWidth), maxWidth);
        });
    });

    // Второй проход: определение максимальной высоты каждой строки с учетом максимальной ширины колонки
    data.forEach((row, rowIndex) => {
        row.cells.forEach((cell, cellIndex) => {
            const cellHeight = estimateHeight(cell.value, maxWidths[cellIndex]);
            maxHeights[rowIndex] = Math.max(maxHeights[rowIndex], cellHeight, minHeight);
        });
    });

    // Третий проход: установка вычисленных размеров ячеек
    data.forEach((row, rowIndex) => {
        row.height = maxHeights[rowIndex]; // Устанавливаем высоту для каждой строки
        row.cells.forEach((cell, cellIndex) => {
            // Устанавливаем ширину для первой строки
            if (rowIndex === 0) {
                cell.width = maxWidths[cellIndex];
            }
        });
    });

    return data;
};


export const tableTabs = [
    {
        key: 'sourceData',
        name: 'Заказы',
        disabled: true
    },
    {
        key: 'processedData',
        name: 'Лист обработки данных'
    },
    {
        key: 'parsedWaybills',
        name: 'Лист загрузки данных',
        disabled: true
    },
];

export function divideArray(coordinates) {
    let minRow = Infinity, maxRow = -Infinity, minCol = Infinity, maxCol = -Infinity;

    coordinates.forEach(coordinate => {
        const [row, col] = coordinate.split('-').map(Number);
        minRow = Math.min(minRow, row);
        maxRow = Math.max(maxRow, row);
        minCol = Math.min(minCol, col);
        maxCol = Math.max(maxCol, col);
    });

    let top = [], bottom = [], left = [], right = [];

    coordinates.forEach(coordinate => {
        const [row, col] = coordinate.split('-');
        if (row === minRow.toString()) top.push(coordinate);
        if (row === maxRow.toString()) bottom.push(coordinate);
        if (col === minCol.toString()) left.push(coordinate);
        if (col === maxCol.toString()) right.push(coordinate);
    });

    return [top, bottom, left, right];
}

export const messageTypes = {
    ERROR: 'Error',
    WARNING: 'Warning',
    INFO: 'Info'
};

export const categories = [
    {
        key: 'RequiredFields',
        name: 'Обязательность параметра'
    },
    {
        key: 'ValueNotFound',
        name: 'Значение не найдено'
    },
    {
        key: 'PortalRestrictions',
        name: 'Ограничения FMCP'
    },
    {
        key: 'StructuralErrors',
        name: 'Структурные ошибки'
    },
    {
        key: 'RuleErrors',
        name: 'Ошибки правил'
    }
];
